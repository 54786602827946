'use client';

import type { JSX } from 'react';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

import { HeaderContainer, renderHeaderVariation } from '@/components';

/**
 * Props for `Header`.
 */
export type HeaderProps = SliceComponentProps<Content.HeaderSlice>;

/**
 * Component for "Header" Slices.
 */
const Header = ({ slice }: HeaderProps): JSX.Element => (
  <HeaderContainer
    data-slice-type={slice.slice_type}
    data-slice-variation={slice.variation}
  >
    {renderHeaderVariation(slice.variation)}
  </HeaderContainer>
);

export default Header;
